@import "../variables.less";

/* news box */
.news-box {
    text-align: left;

    // if hidden
    &.hidden {
        display: none;
    }

    // text styles for all devices
    .text-box {

        a {
            text-decoration: none;
        }
        .category,
        .title,
        .perex {
            display: block;
            line-height: 1.375;
        }

        .category {
            text-transform: uppercase;
            font-size: 12px;
            color: @Green-4;
        }
        .title {
            font-size: 18px;
            font-weight: 700;
            color: @Grey-4;
        }
        .perex {
            color: @Grey-4;
        }
    }

    @media @tablet, @desktop {

        .image-box {
            overflow: hidden;

            img {
                width: 100%;
            }
        }
        .text-box {
            position: relative;
            background-color: @White;
            border-radius: 3px;

            .category {
                padding-bottom: 15px;
            }
            .title {
                padding-bottom: 15px;
                min-height: 64px;
            }
        }
    }

    // different padding for desktop and tablet (not much space on tablet)
    @media @desktop {

        .image-box {
            height: 180px;
        }

        .text-box {
            top: -40px;
            margin: 0 20px -40px;
            padding: 25px 20px 0;
        }
    }

    @media @mobile {

        a {
            display: flex;
            flex-direction: row;

            .image-box {
                padding-right: 25px;

                img {
                    width: 140px;
                }
            }

            .text-box {

                .title {
                    padding-bottom: 10px;
                }
            }
        }
    }

    @media @small-mobile {

        .image-box {

            img {
                width: 100px;
            }
        }

        .text-box {

            .title {
                padding-bottom: 0;
            }

            .perex {
                display: none;
            }
        }
    }
}
    /* /news box */