 /* # Star rating

This is just a rought extract from windows-star-rating.less and arrow styles used in index
Goal is to prepare standalone component for reviews slider

Removed dependency on id #reviews

  */

/* standard variables include */
@import '../variables.less';

/* Slider reviews */
.slider-reviews {
    position: relative;

    &:before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 0;
        height: 0;
        opacity: 0;
    }

    /* loader */
    &.is-loading:before {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9000;
        width: 100%;
        height: 100%;
        opacity: 1;
        background: url("../i/ajax-spinner.gif") 50% 25% #fff no-repeat;
    }


    .slider-review-slide:nth-child(3n + 2) {
        &:before {
            background: url(../i/rating-stars/quotes-orange.png) no-repeat center center;
        }
    }
    .slider-review-slide:nth-child(3n + 3) {
        &:before {
            background: url(../i/rating-stars/quotes-orange.png) no-repeat center center;
        }
    }


    /* slide tiles */
    .slider-review-slide {
        padding-left: 50px;
        position: relative;
        flex-grow: 1;
        flex-basis: 0;

        &:before {
            content: ' ';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 10px;
            width: 23px;
            height: 18px;
            background: url(../i/rating-stars/quotes-orange.png) no-repeat center center;
            margin-right: 10px;
        }
    }

    .slider-review-thing {
        display: none;
    }
    .slider-review-text {
        margin-top: 20px;
        max-height: 190px;
        overflow: hidden;
    }
    .slider-review-author {
        display: inline-block;
        font-weight: 700;
        font-size: 20px;
        color: @Text;
    }
    .slider-review-date {
        display: inline-block;
        padding-bottom: 0;
        margin-bottom: 0;
        float: right;
        font-size: 1rem;
        color: #aaabb6;
    }


    /* star rating */
    .star {
        display: inline-block;
        width: 18px;
        height: 17px;
        background: url("../i/rating-stars/star.png");
        background-size: 19px auto;
    }
	[itemprop="ratingValue"],
	.rating-value {
        display: none;
    }


    /* arrow box */
    .arrow-box {
        position: absolute;
        display: block;
        width: 60px;
        height: 90px;

        @media @tablet, @mobile {
            display: none;
        }

        a {
            display: block;
            position: absolute;
            z-index: 100;
            width: 100%;
            height: 100%;
        }

        span {
            position: absolute;
            left: 0;
            display: block;
            height: 30px;
            text-decoration: none;

            B {
                position: fixed;
                top: 0;
                right: 6000px;
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                display: block;
                border-right: 2px solid;
                width: 0;
                height: 30px;
                color: #aaa;
                transition: color 0.5s ease;
                animation: arrow-color-change 2s infinite;
            }

            &:before {
                right: -19px;
                transform: rotate(135deg);
            }

            &:after {
                right: -40px;
                transform: rotate(45deg);
            }

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2) {
                top: 20px;
            }

            &:nth-child(3) {
                top: 40px;
            }

            &:nth-child(2):before,
            &:nth-child(2):after {
                animation-delay: 0.3s;
            }

            &:nth-child(3):before,
            &:nth-child(3):after {
                animation-delay: 0.6s;
            }
        }

        &:hover {
            span {
                &:before,
                &:after {
                    color: #ffffff;
                    animation: none;
                }
            }
        }


        /* left and right arrow */
        &.arrow-left,
        &.arrow-right {
            top: 50%;
            margin-top: -50px;
            position: absolute;
            cursor: pointer;
            z-index: 400;

            &:hover {
                span {
                    &:before,
                    &:after {
                        color: #41424e;
                        animation: none;
                    }
                }
            }

            @media @tablet,
                @mobile {
                    top: 115%;
            }
        }

        &.arrow-left {
            transform: rotate(90deg);
            left: -100px;

            @media @tablet,
                @mobile {
                    left: 0;
            }
            @media (max-width:1200px) {
                left: -20px;
            }
        }

        &.arrow-right {
            transform: rotate(-90deg);
            right: -100px;

            @media @tablet,
                @mobile {
                    right: 0;
            }
            @media (max-width:1200px) {
                right: -20px;
            }
        }

    }
}
