/* # SLIDE DOWN

Centered slide down button, used on HP, FAV and other pages

HTML to add is

<!-- slider slide down -->
<div class="slide-down"><a class="arrow_box" id="js-arrow-box"><span></span></a></div>

 */

@import '../variables.less';

#top {
	position: relative;

	/* slide-down */
	.slide-down {
		position: absolute;
		left: calc(50% - 30px);
		bottom: 0;

		.arrow-box {		
			position: absolute;
			bottom: -29px;
			z-index: 10;
			text-align: center; 
			box-shadow: 0px 4px 12px 0px rgba(167, 167, 167, 0.5); 
			width: 60px;
			height: 60px;
			display: block;
			margin: 0 auto;
			border-radius: 50%;
			background-color: @White;

			span {
				display: block;
				position: absolute;
				content: "";
				width: 15px;
				height: 15px;
				transform: rotate(45deg);
				border-right: 2px solid #8831fc;
				border-bottom: 2px solid #8831fc;
				top: 18px;
				left: 22px;
			}
		}
		@media @mobile {
	        display:none;
		}
	}

}

