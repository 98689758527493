/* # Component for Awards section

Used on /windows, /compare-antivirus and others

 */

@import "../variables.less";

#awards {

	.row {
	    margin-top: 20px;

	    /* grid override to get 2 colum layout on bigger mobiles */
	    .span3,
	    .span4 {

	        @media @mobile {
				flex-basis: 50%;
	        }
	        @media @small-mobile {
	            width: auto;
	        }
	    }

	    .logo {
	        display: block;
	        width: 100%;
	        height: 80px;
	        position: relative;
	        margin-bottom: 10px;

	        img {
	            position: absolute;
	            margin: auto;
	            top: 0;
	            bottom: 0;
	            left: 0;
	            right: 0;
	        }
	    }
	    p {
	    	text-align: center;
	    }
	}
}