/* Avast animation */
    // styles from windows: animation v 12less;
    // now included separately, because keyframes are not loaded for Android and Mac devices (it saves users 1.2Mb download)

	#avast-animation-div {
		position: relative;
		margin: auto;
	  
	  
	  @media only screen and (min-width: 1056px) {
		  width: 1054px;
		  height: 532px;
	  }
	  
	  @media only screen and (max-width: 1055px) and (min-width: 768px) {
		  width: 766px;
		  height: 387px;
	  }
	  
	  @media only screen and (max-width: 767px) {
		  width: 484px;
		  height: 244px;
	  }
	  
	  @media only screen and (max-width: 486px) {
		  width: 358px;
		  height: 181px;
	  }
	  
	  @media only screen and (max-width: 360px) {
		  width: 298px;
		  height: 150px;
	  }
	  
	  & > div {
		width: 0.38rem;
		height: 0.38rem;
		border: 0;
		border-radius: 50%;
		background: white;
		background: rgba(255, 255, 255, 0.9);
		position: absolute;
		z-index: 100;
		opacity: 0;
		transform-origin: center center;
		will-change: opacity, transform;
		transform: translateZ(0);
		backface-visibility: hidden;
		perspective: 1000px;
		animation-duration: 15s;
		animation-iteration-count: infinite;
		animation-fill-mode: none;
		animation-timing-function: ease-in-out;
		/* ------------------------------- */
		/* ------ paused /playing -------- */
		animation-play-state: paused;
		/* ------------------------------- */
	  }
	  
	  &.play-animation > div {
		/* added class to play */
		animation-play-state: running;
	  }
	  
	  @media only screen and (max-width: 1055px) and (min-width: 768px) {
		& > div {
		  width: 0.62rem;
		  height: 0.62rem;
		}
	  }
	  
	  @media only screen and (min-width: 1056px) {
		& > div {
		  width: 1rem;
		  height: 1rem;
		}
	  }

	}


	  
	#avast-animation {
	position: relative;
	margin: 0 auto;
	/* ----------------------- */
	/* --- set breakpoints --- */
	/* ----------------------- */
	
	
	.bg-image {
		display: block;
		width: 100%;
		opacity: .38;
	  }
	}

	//  End of content from windows-animation-v12.less