@charset "utf-8";

/*
# Windows homepage
 */

@import '../style-guide/variables.less';
@import '../style-guide/components/comparison-table-v14.less';
@import '../style-guide/components/slider-reviews.less';
@import '../style-guide/components/slider-slide-down.less';
@import '../style-guide/components/awards.less';
@import '../style-guide/components/blogposts.less';
@import '../style-guide/mixins/center.less';

/* map animation */
@import '../windows-animation-map-v12.less';

/* ## Top  */
#top {

	&.fullscreen {
        background-image: linear-gradient(rgba(34,34,34,0.4), rgba(34,34,34,0.4)),url(../../i/windows/bg-windows-v13.jpg);;
		//background-color: #767269;
		//background-image: url('../i/windows/bg-windows-v12.jpg');

		@media @mobile {
			background-position: 40% 50%;
		}
	}

	.absolute-center {
		text-align: center;
	}

	h1 {
		padding: 0 0 50px;
		font-size: 56px;
	}

	p.subh1 {
		padding: 0 0 50px;

		a {
			color: #FFF;

			u {
				text-decoration: underline;
			}
		}
	}
	P.pc {
		color: #a3a9bb;
		A {
			color: #a3a9bb;
			text-decoration: underline;
		}
	}


/* buttons */
	.butt-holder {
		.button {
			display: table;
			margin: 0 auto 20px;
		}
	}


/* other platform links */
	.platform-links {
		display: table;
		margin: 0 auto;
		width: 60%;

		@media @small-mobile {
			display: block;
			text-align: center;
			width: auto;
			max-width: 80%;
		}

		a {
			display: table-cell;
			padding: 20px;
			width: 50%;

			@media @small-mobile {
				display: block;
				width: auto;
				margin: 0 auto;
			}

			img {
				margin-bottom: 10px;
			}

			p {
				margin: 0 auto;
				padding: 0 40px;
				font-size: 18px;
				text-decoration: underline;

				@media @small-mobile {
					padding: 0;
				}
			}
		}
	}
	/* banner product of the year */
	.banner-product-of-the-year {
		background-color: #8050ff;

		.banner-text {
			padding-left: 10px;
			@media @mobile {
			padding-bottom: 30px;
			font-size: 18px;
			padding-left: 18px;
			padding-right: 18px;
			}
			@media @tablet {
			padding-bottom: 42px;
			}
		}


		SPAN {
			display: inline-block;
			vertical-align: middle;
			color: @White;
			font-size: 24px;
			font-weight: 300;
		}
		IMG {
			margin-top: -25px;
			display: inline-block;
			vertical-align: middle;
		}
		.banner-img {
			padding-bottom: 20px;
			IMG {
				@media @mobile {
					max-width: 115px;
					}
			}

		}
	}
}

// comparison table
.comparison {
	.lower-space {
			height: 160px;
	}
}

/* floatbar */
#floatbar {
    position: fixed;
	top: -70px;
    width: 100%;
    visibility: hidden;
    transition: .5s ease-in-out;
    border-bottom: 2px solid @TabsBorder;
	box-shadow: 0px 0px 7px -5px rgba(0, 0, 0, 0.3);


    &.sticky {
        visibility: visible;
        height: 70px;
        top: 0;
        z-index: 1001;
    }

    .AVsizer {
        height: 70px;
        max-width: none;
    	text-align: right;
    }

    .floatbar-logo {
    	position: absolute;
    	top: 18px;
    	left: 50px;
    }

    .floatbar-label {
    	font-size: 16px;
    	font-weight: bold;
    }

    .floatbar-button {
    	margin: 10px 30px 15px 15px;
    }
}

#comparison-table {
	H2 {
		padding-top: 120px;
	}
}

/* Top - safe version */
[class*="mod-ar"],
[class*="mod-zh"],
.mod-en-ae,
.mod-en-id,
.mod-en-in,
.mod-en-my,
.mod-en-ph,
.mod-en-sg,
.mod-fa-ir,
.mod-fil-ph,
.mod-id-id,
.mod-ko-kr,
.mod-ms-my,
.mod-ru-ru,
.mod-th-th,
.mod-tr-tr,
.mod-tl-ph,
.mod-vi-vn,
.mod-hi-in {

	#top {
		background-image: url('../../i/windows/bg-windows-alt-v12.jpg');
	}
}

/*  Top - local versions */
/* fr-fr */
.mod-fr-fr {

	#top {
		background-image: url('../../i/index/bg-windows-fr.jpg');
	}
}
/* de-de */
.mod-de-de {

	#top {
		background-image: url('../../i/index/bg-windows-de.jpg');

			H1 {
			font-size:52px;
		}
	}
}
/* ja-jp */
[class*="mod-ja"] {
	#top {
		background-image: url('../../i/index/bg-index-jp.jpg');
	}
}

/* latam */
.mod-es-mx,
.mod-es-ar,
.mod-es-cl,
.mod-es-co,
.mod-es-ww {

	#top {
		background-image: url('../../i/index/bg-windows-latam.jpg');
	}
}
/* ru-ru */
.mod-ru-ru {

	#top {
		background-image: url('../../i/index/bg-windows-ru.jpg');
	}
}
/* pt-br */
.mod-pt-br {
	#top {
		background-image: url('../../i/index/bg-windows-br.jpg');
	}
}



/* common properties */

#content-holder {

	h2 {
		padding-bottom: 60px;
	}
	.subh2 {
		padding-bottom: 40px;
	}
}


/* ## Comparison table

It uses comparison-table.less component
*/

#comparison {
	.AVsizer {
		padding: 120px 0 55px 0;
	}

	h2,
	.subh2 {
		text-align: center;
	}

	.tab-com {
		.buttons.upper {

			.val {
				padding-bottom: 30px;
			}
		}
		.buttons.lower {

			.description{
				text-align: left;
				font-size: 14px;
			}
			.learn-more {
				text-decoration: underline;
				color: @Grey-4;
			}
			.no-pad {
				padding: 0 !important;
				}
		}

		// Hide Ultimate
		.c5 {
			display: none;
		}

		// Premier dots are orange
		.c4 SPAN.yes {
            background-color: @Orange;
        }


	}

}


#more-offer,
#download {
	.AVsizer {
		text-align: center;
		padding: 105px 0;
	}
}

#more-offer {
	H3 {
		font-size: 26px;
		padding-bottom: 30px;
	}
	.subh3 {
		font-size: 18px;
		padding-bottom: 30px;
	}
	.button.transparent {
		box-shadow: none;
		SPAN {
			border: 1px solid #d0d1da;
			color: #444f5f;
			box-shadow: none;
			&:hover {
				background-color: transparent;
			}
		}

	}
}
#download {
	.subh2 {
		font-size: 24px;
	}
}

.comparison {
.recommended {
	.val {
		background-color: @White;
		 &.c5 {
			background-color: @Orange;
			H5 {
				font-size: 13px;
				text-transform: uppercase;
				padding-top: 12px;
				padding-bottom: 12px;
				A {
					color: @White;
				}

			}
		}
	}
}
}


/* # Localizations  */

[class*="mod-en"] {
	#comparison {
		h2 {
			font-size: 46px;
		}
	}
}

[class*="ar-ww"] {
	a.button.white.big.bi-download-link {
		float: none;
	}
}

.mod-pl-pl, [class*="mod-cs"], [class*="mod-es"], .mod-hu-hu, .mod-hi-in, .mod-vi-vn{
	.tab-com TR.buttons TD .button SPAN:first-child {
		padding: 17px 26px 16px;
		@media @mobile, @tablet {
			padding: 17px 0 16px;
		}
	}
}

//avast 3d logo- Website-2561
#header {
	.logo-3d {
		top: -16px;
		left: 15px;
		position: absolute;
	}

	.logo-3d-wrap {
		height: 150px;
	}
}
