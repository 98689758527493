@charset "utf-8";

/* Comparison table
===================
Provides styling and responsive behaviour for comparison table

*/

@import '../variables.less';

/* # Comparison table V13 */
.comparison {
	position: relative;

	.floating {
		display: block;
		visibility: hidden;
		position: sticky;
		width: 100%;
		background-color: white;
		z-index: 500;
		opacity: 0;
		transition: opacity .5s ease-out;

		&:after {
			position: absolute;
			left: 0;
			bottom: -10px;
			display: block;
			content: "";
			height: 10px;
			width: 100%;
			background: linear-gradient(to bottom, rgba(200,200,200,1) 0%,rgba(200,200,200,0) 100%);
		}

		&.visible {

			// not displayed on mobiles
			// @media @tablet, @desktop {
			// 	visibility: visible;
			// 	opacity: 1;
			// 	top: -1px;
			// }
			visibility: visible;
			opacity: 1;
			top: -1px;
		}

		&.scrollable {
			position: absolute;
		}

		H5 {
			padding-bottom: 20px;
			font-size: 18px;
		}
	}
}


/* ## Borders */
.floating {
	td {
		border-right: 1px solid @Grey-9;
	}

	.prices {
		@media @mobile {
			display: flex;
		}

		td {
			@media @mobile {
				border-right: 1px solid @White !important;
			}

			&:last-child {
				@media @mobile {
					border-right: none !important;
				}
			}
		}
	}
}
.static {
	td {
		@media @desktop {
			border-right: 1px solid @White;
			border-bottom: 1px solid @ComparisonColumBorder;
		}
	}
}

/* ## Desktop version */
.tab-com {
    margin: 0 auto 0;
    border-collapse: collapse;
    width: 100%;

	TD {
		text-align: center;
	    }

	TD.val {
		@media @desktop {
			background-color: @Grey-9;
		}

        @media @mobile {
        	width: 25%;
        }
	}
	
	TD.description {
		text-align: left;
	}

    // Learn more link
    .sub {

    	A {
			text-decoration: underline;
			color: @Grey-4;
			font-size: 15px;

			@media @mobile {
				font-size: 14px;
			}
		}
	}

/* #### Headings part */
	TR.titles {

		TD {
		    padding: 20px 0 10px;
		    border-bottom: none;
			font-weight: normal;
			font-size: 16px;

			@media @mobile {
				border-right: 1px solid @White;
				padding-bottom:24px;
					&.aligned {
						padding-top:30px;
						}
			}

			&:last-child {
				@media @mobile {
					border-right: none;
				}
			}

			&.val {
				text-align: center;
			}

			H5 A {
				font-size: 18px;
				font-weight: normal;
                color: @Text;

				SPAN {
					display: block;
					font-weight: 700;
                    color: @HeadingText;
				}
			}
	    }
	} 

/* #### Product images part */
	TR.products {
		@media @mobile {
			display: flex;
		}

		TD {
			border-bottom: none;
			padding: 0;

			@media @mobile {
				border-right: 1px solid @White;
			}

			&:last-child {
				@media @mobile {
					border-right: none;
				}
			}

			IMG {
		    width: 80%;
			margin: 0 auto 0;
			max-width: 80px;
            display: block;
		    }
		}
	}

/* #### Prices */
	TR.prices {
		@media @mobile {
			display: flex;
		}

		TD {
			padding: 15px 0px;
			@media @mobile {
				border-right: 1px solid @White;

				&.aligned {
					padding-top:30px;
					.price {
						padding-top:6px;
					}
				}
			}
	
			&:last-child {
				@media @mobile {
					border-right: none;
				}
			}

			.price {
				font-size: 22px;
				padding-bottom: 0;

				.year {
					font-size: 16px;
				}

				@media @mobile {
					font-size: 16px;
					line-height: 1;

					&.free {
						padding-bottom:16px;
					}
				}
			}
		}
	}


/* #### Buttons part */
	TR.buttons {
		TD {
		    border-bottom: none;
		    padding: 30px 0 120px;
			vertical-align: top;

			@media @mobile {
				background-color: transparent;
				padding-bottom:60px;
			}
		    
			.button {
			    width: 80%;

		    	SPAN {
			    width: 100%;
				padding: 16px 0 17px;
				font-size: 15px; // smaller font
			    }

			    // icon adjustement for smaller font
			    &.cart {

			    	SPAN::before {
			    		margin-right: 8px;
			    		top: 3px;
			    	}
			    }

			    @media @mobile {
				/* font size on small displays needs to be small to fit the buttons */
				  	&,
				  	&.big {
				  		SPAN {
				  			min-height: 68px;
				  		}
				  	}
			  	}

			    @media @small-mobile {
				/* font size on small displays needs to be small to fit the buttons */
				  	&,
				  	&.big {
				  		SPAN {
				  			font-size: 12px;
				  		}
				  	}
			  	}
		    }

		    .trial {
		    	padding-top: 15px;
		    	font-size: 14px;
	            font-weight: 400;
		    }
		}
	}

/* #### Top backgrounds */
	TR.prices {
		@media @desktop {
		border-bottom: 1px solid @White;
		}
	}	


/* #### Features

Snippet {ComparisonTableFeatures}
 */
	TR.feature {

		TD {
			padding: 15px 0px;

			@media @mobile {
				border-bottom: 2px solid @Grey-9;
				border-right: 1px solid @White;
			}

			&:last-child {
				@media @mobile {
					border-right: none;
				}
			}
		}


		TD.txt {
		    text-align: left;
		    font-size: 14px;
            font-weight: normal;
			padding-right: 30px;
			
			@media @mobile {
				border-bottom: 1px solid @White;
				border-right: none;
				border-top: 10px solid @White;
			}

            P {
            	padding-bottom: 5px;
            	font-size: 14px;
            }

            H5 {
            	line-height: 16px;
            	padding-bottom: 5px;
            }

		    H5 > B {
		    		display: inline;
		    		position: relative;
		    		margin-right: 5px; // Makes space for i icon
		    		font-size: 16px;
		    		font-weight: 700;
                    color: @HeadingText;
                    max-width: 70%;
                    

                    // info icon
			    	.info {
			    		position: absolute;
			    		top: 0;
			    		opacity: 0;
			    		right: -30px;
			    		display: inline-block;
			    		width: 18px;
			    		height: 18px;
			    		margin-left: 10px;
			    		background-color: #BABBC0;
			    		border-radius: 50%;
			    		text-align: center;
			    		font-size: 10px;
			    		line-height: 18px;
			    		color: @White;
			    		cursor: pointer;
			    		transition: opacity .5s ease-out;

			    		&:hover {
			    			background-color: @Grey-0-new;
			    		}
			    		&.visible {

			    			// not displayed on mobiles
			    			@media @tablet, @desktop {
			    				opacity: 1;
			    			}
			    		}
			    		&:before {
			    			content: " ";
			    			display: block;
			    			position: absolute;
			    			top: 4px;
			    			right: 0;
			    			left: 0;
			    			width: 2px;
			    			height: 2px;
			    			background-color: @White;
			    			margin: auto;
			    		}
			    		&:after {
							content: " ";
			    			display: block;
			    			position: absolute;
			    			top: 7px;
			    			right: 0;
			    			left: 0;
			    			width: 2px;
			    			height: 6px;
			    			background-color: @White;
			    			margin: auto;
			    		}
			    	}

			    	// info popup
			    	.infotext {
			    		position: absolute;
			    		z-index: 100;
			    		left: -165px;
			    		top: 40px;
			    		display: none;
			    		width: 350px;
			    		padding: 30px;
			    		color: @White;		    		
			    		background-color: @Grey-0-new;
			    		font-size: 14px;
			    		font-weight: normal;
			    		text-align: left;

			    		&.show {
			    			display: block;
			    		}

			    		&::before {
			    			content: "";
			    			display: block;
			    			position: absolute;
			    			top: -10px;
			    			left: calc(50% - 9px);
			    			width: 0;
							height: 0;
							border-style: solid;
							border-width: 0 9px 10px 9px;
							border-color: transparent transparent @Grey-0-new transparent;
			    		}

			    		// infotext with product box
			    		&.product {
			    			padding-left: 150px;
			    			text-align: left;

			    			// make those spans block elements 
			    			.name,
			    			.claim,
			    			.price {
			    				display: block;
			    				padding-bottom: 10px;
			    			}

			    			img {
			    				float: left;
			    				margin-left: -120px;
			    			}

			    			/* Tooltip with box styling */
			    			.name {
			    				font-weight: bold;
			    			}
			    			.price {
			    				padding-bottom: 0;

			    				b {
			    					font-weight: normal;
			    					font-size: 18px;
			    				}
			    				.orange {
			    					color: @Orange;
			    				}
			    			}
			    		}
			    	}
		    	}
		    	SPAN.label {
		    		position: relative;
		    		top: -1px;
		    		display: inline-block;
		    		background-color: @ComparisonLabelBackground;
		    		margin: 0 18px 0 13px;
		    		padding: 2px 2px 1px;
		    		font-size: 10px;
		    		color: @ComparisonLabelText;
		    	}

	    	    
		    }
		TD.val {

			SPAN {
		    display: inline-block;
		    text-align: center;
		    zoom: 1;
		    position: relative;

		    width: 8px;
			  height: 16px;
			  border: solid @ComparisonDot;
			  border-width: 0 2px 2px 0;
			  transform: rotate(45deg);


		    	B {
				    position: fixed;
				    top: 0;
				    left: -5000px;
			    }
		    }
		}

		TD.val.empty {
			background-color: transparent;
			
			@media @mobile {
				border-bottom: 1px solid @Grey-9;
				border-top: 1px solid @Grey-9;
			}

			&.c1 {
				@media @mobile {
					border-left: 1px solid @Grey-9;
				}
			}
		}
	}
    
}

/* ## Mobile version  */

@media @mobile {

    .tab-com,
    .tab-com tbody {		
		width: 100% !important;
    }

    .tab-com {

	    .titles {
			border: 0 !important;
			display: flex;
	        margin: 0 !important;
			padding: 0 !important;
	    }
	    .titles td h5 {
	        font-size: 14px;
	    }

	    tr {
	        position: relative !important;
	    }

	    td {
			background-color:#f2f2f6;
	        width: auto;
	        float: left !important;
			padding-bottom:24px;
		}

	    td.txt {
	        width: 100% !important;

	        p {
	        	font-size:12px !important;
	        }
	    }
	    td.description {
	        display: none;
	    }


	    .top td h3,
	    .txt b {
	        font-size: 14px !important; 
	    }

	}


}
/* # Two columns only

Layout exceptions - for version (without Free Antivirus and Pro Antivirus)

It hides FAV, PAV and updates widths to fit decreased number of columns
*/

.comparison {

/*  #### default without PAV */
	&.products-2 {
    
    .c1,
    .c2 {
        display: none;
    }
    .c1,
    .c2,
    .c3,
    .c4 {
    	width: 23%;

    	@media @mobile {
	        width: 50%;
	    }
    }

    @media @mobile {

        .txt {
            width: 100% !important;
        }
    }
}

/* # Three columns only

Layout exceptions - for version (without Pro Antivirus)

It hides PAV and updates widths to fit decreased number of columns
 */

/*  #### default without PAV */

	&.products-3 {
	    .c1,
	    .c2,
	    .c3,
	    .c4 {
	    	width: 20%;

	    	@media @mobile {
	        	width: 33.3%;
	        }
	    }

	    @media @mobile {

	    	.txt {
		        width: 100% !important;
		    }
	    }

	}

	/* #### without FA */
	&.products-3.no-free {

		.c1 {
	        display: none;
	    }
	    .c2 {
	        display: table-cell;
	    }

	}

	/* #### without FA */
	&.products-4.no-pav {

	    .c1,
	    .c2,
	    .c3,
	    .c4 {
	    	width: 18%;

	    	@media @mobile {
	        	width: 25%;
	        }
	    }

	    @media @mobile {

	    	td.txt {
				padding-left: 20px;
				width: 100% !important;
		    }
	    }

	}

}


/* Localisation */



// Same button height
@media @tablet, @desktop {

	[class*="mod-sv"],
	[class*="mod-de"],
	[class*="mod-it"],
	[class*="mod-nl"],
	[class*="mod-pl"],
	[class*="mod-uk"],
	[class*="mod-tl"],
	[class*="mod-ms"],
	.mod-pt-pt {

		.comparison {

			.buttons.lower {

				.button {
					display: table;
					margin-left: auto;
					margin-right: auto;

					SPAN {
						height: 68px;
						display: table-cell;
						vertical-align: middle;
					} 
				}
			}
		}
	}
}

// Wider columns for PT regions
@media @tablet, @desktop {

	[class*="mod-pt"] {

		.comparison.products-4.no-pav {

			.c1,
			.c2,
			.c3,
			.c4{
				width: 19%;
			}

			.button.cart span::after {
				margin-left: 5px;
			}
		}
	}
}

// Wider buttons for PT regions to better fit translated text
@media @tablet, @desktop {

	[class*="mod-pt"] {

		.tab-com TR.buttons TD .button {
			width: 90%;
		}
	}
}


 /* RTL regions */

[class*="mod-he"],
[class*="mod-ar"] {

	.tab-com {

		TD.description {
			text-align: right;
		}

	    TR.products {
	        TD.description {
	            text-align: right;
				padding-right: 30px;
			}
	    }	     
	    TR.feature { 

	        TD.txt {
				text-align: right;
	        
				SPAN.label {
					margin: 0 0 0 7px;
				}

				// infotext

				H5 > B {

                    // info icon margin change
					.info {
						margin-left: 0;
						margin-right: 10px;
					}					

					.infotext {
						text-align: right;
						
						&.product {
							padding-left: 40px;
							padding-right: 150px;
							text-align: right;

							img {
								float: right;
								margin-left: 0;
								margin-right: -120px;
							}
						}
					}
				}
			}
	    }
	    TR.bottom TD P {
	        text-align: center;
	    }
	    TR.bottom TD .button {
	        text-align:center;
	    }
	}
}

// Localizations

[class*="mod-de"],
[class*="mod-fr"],
[class*="mod-el"],
[class*="mod-cs"],
[class*="mod-ru"],
[class*="mod-id"],
[class*="mod-ms"]
 {
	.tab-com TR.prices TD .price .year {	
		@media @mobile {
			display: block;
		}
	}
}

[class*="mod-fr"],
[class*="mod-ru"],
[class*="mod-uk"],
[class*="mod-uk"] {
	.tab-com TR.titles TD H5 A SPAN,
	.floating .prices h5 a span {
		word-break: break-word;
	}
}
// /Localizations
