/* # CENTERER

For centering text blocks within spanX elements

To use it you need to set height of the containing element (for both @tablet and @mobile)!

Warning - not working in IE8
 */

@import '../variables.less';

.center {
  
	.horizontal {

		@media @desktop, @tablet {
		  position: relative;
		  left: 50%;
		  transform: translateX(-50%);
		}
	}

	.vertical {

		@media @desktop, @tablet {
		  position: relative;
		  top: 50%;
		  transform: translateY(-50%);
		}
	}
}

